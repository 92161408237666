<template>
    <div class="number_pagination_wrap" v-if="paginationData.total > 0">
        <div class="page_btn_wrap el-page" v-if="maxPage > 1">
            <button class="first_end" @click="changePage('page',1)" v-if="page !== 1"></button>
            <button class="previous" @click="changePage('page',page-1)" :disabled="page === 1" v-if="page !== 1"></button>
            <el-pagination
                    :hide-on-single-page=true
                    :page-size="paginationData.size"
                    :current-page.sync="page"
                    :pager-count="11"
                    :total="paginationData.total"
                    @current-change="changePage('page', page)"
                    class="page_btn_wrap"
                    layout="pager"
            >
            </el-pagination>
            <button class="next" @click="changePage('page',page+1)" :disabled="maxPage === page" v-if="maxPage !== page"></button>
            <button class="last_end" @click="changePage('page',maxPage)" :disabled="maxPage === page" v-if="maxPage !== page"></button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PaginationDefaultLayout",
        mixins: [],
        components: {
        },
        inject: ['setPage'],
        provide() {
            return{
            }
        },
        props: {
            paginationData: {default: {page: 1, size: 10, total: 0,}},
        },
        data() {
            return{
                page: 1,
                size: 10,
                sizeList: [10, 30, 50],
                maxPage: 0,
            }
        },
        beforeRouterEnter() {},
        created() {
        },
        mounted() {
            this.page = this.paginationData.page;
            this.size = this.paginationData.size;
            this.maxPage = Math.ceil(this.paginationData.total / this.paginationData.size)
        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
        },
        methods:{
            changePage(key, value) {
                this.setPage(key, value);
            },
        },
        watch: {
            'paginationData.page': {
                deep: true,
                handler: function (val, oldVal) {
                    this.page = this.paginationData.page;
                    this.maxPage = Math.ceil(this.paginationData.total / this.paginationData.size)
                }

            }
        },
    }
</script>

<style scoped>

</style>
